export const ROUTES = {
  HOME: '/',
  CHECKOUT: '/checkout',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  ORDERS: '/orders',
  REFUNDS: '/refunds',
  HELP: '/help',
  LOGOUT: '/logout',
  OFFERS: '/offers',
  ORDER_RECEIVED: '/order-received',
  PRODUCT: '/products',
  ORDER: '/order',
  PRIVACY: '/privacy',
  TERMS: '/content/terms',
  CONTACT: '/contact',
  SHOPS: '/shops',
  CATALOG: '/catalog',
  CART: '/cart',
  PAYMENTS: '/payments',
  OFERTA: '/content/oferta',
  ABOUT: '/content/about',
  CONTACTS_CONTENT: '/content/contacts',
  TERMS_CONTENT: '/content/terms',
};

export const mobileHeaderLinks = [
  { href: ROUTES.CATALOG, icon: null, label: 'nav-menu-catalog' },
  { href: ROUTES.TERMS, icon: null, label: 'nav-menu-terms' },
  { href: ROUTES.ABOUT, icon: null, label: 'nav-menu-about' },
  { href: ROUTES.OFERTA, icon: null, label: 'nav-menu-offer' },
  { href: ROUTES.CONTACT, icon: null, label: 'nav-menu-contact' },
  // { href: ROUTES.CONTACTS_CONTENT, icon: null, label: 'nav-menu-contact' },
  // { href: ROUTES.TERMS_CONTENT, icon: null, label: 'nav-menu-terms' },
  // { href: ROUTES.CART, icon: null, label: 'nav-menu-cart' },
  // { href: ROUTES.SHOPS, label: 'nav-menu-shops' },
  // { href: ROUTES.OFFERS, label: 'nav-menu-offer' },
  // { href: ROUTES.HELP, label: 'nav-menu-faq' },
  // { href: ROUTES.CONTACT, label: 'nav-menu-contact' },
];

export const desktopHeaderLinks = [
  { href: ROUTES.CATALOG, icon: null, label: 'nav-menu-catalog' },
  { href: ROUTES.TERMS, icon: null, label: 'nav-menu-terms' },
  { href: ROUTES.ABOUT, icon: null, label: 'nav-menu-about' },
  { href: ROUTES.OFERTA, icon: null, label: 'nav-menu-offer' },
  { href: ROUTES.CONTACT, icon: null, label: 'nav-menu-contact' },
  // { href: ROUTES.CONTACTS_CONTENT, icon: null, label: 'nav-menu-contact' },
  // { href: ROUTES.TERMS_CONTENT, icon: null, label: 'nav-menu-terms' },
  // { href: ROUTES.CART, icon: null, label: 'nav-menu-cart' },
  // { href: ROUTES.SHOPS, icosn: null, label: 'nav-menu-shops' },
  // { href: ROUTES.OFFERS, icon: null, label: 'nav-menu-offer' },
  // { href: ROUTES.HELP, label: 'nav-menu-faq' },
  // { href: ROUTES.CONTACT, label: 'nav-menu-contact' },
];
