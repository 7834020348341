import Scrollbar from '@components/ui/scrollbar';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import {Fragment, useState} from 'react';
import { getIcon } from '@lib/get-icon';
import { CaretDown } from '@components/icons/caret-down';
import * as groupIcons from '@components/icons/groups';
import { Type } from '@framework/types';
import { useRouter } from 'next/router';
import Link from '@components/ui/link';
import { ArrowDownIcon } from '@components/icons/arrow-down';
import {log} from "util";
import GridWithLoader from "@components/products/grids/grid-with-loader";
import {observer} from "mobx-react";
import {useTranslation} from "next-i18next";
import {IconSortNo} from "@components/icons/sorting/icon-sort-no";
import {IconSortAsc} from "@components/icons/sorting/icon-sort-asc";
import {IconSortDesc} from "@components/icons/sorting/icon-sort-desc";
import Image from "next/image";
import {IconCaretUp} from "tabler-icons";

interface GroupsMenuProps {
  className?: string;
  groups?: Type[];
  defaultGroup?: Type;
  variant?: 'colored' | 'minimal';
  sortState?: (state) => void;
  priceSortValues: object;

}

export const GroupsMenu: React.FC<GroupsMenuProps> = ({
  className,
  groups,
  defaultGroup,
  variant = 'colored',
  sortState,
  priceSortValues,
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { query } = router;

  const selectedMenu =
    groups?.find((type) => router.asPath.includes(type.slug)) ?? defaultGroup;

  const [sortValueState, setSortValueState] = useState('');
  const [sortMode, setSortMode] = useState(false)

  return (
    <>

    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        className={cn(
          "flex items-center h-8 md:h-10 py-1 md:py-1.5 px-3 md:px-4 text-sm md:text-base bg-gray-100 bg-opacity-90 rounded border border-border-200 font-semibold text-heading transition-colors duration-200 focus:outline-none hover:border-accent-hover focus:border-accent-hover hover:bg-accent focus:bg-accent hover:text-light focus:text-light",
          // {
          //   'bg-gray-50 border border-border-200 rounded-lg px-3':
          //     variant === 'minimal',
          //   'bg-light xl:border border-border-200 xl:text-accent xl:min-w-150 rounded':
          //     variant === 'colored',
          // },
          className
        )}
      >
        {({ open }) => (
          <>
            {/* {variant === 'colored' && selectedMenu?.icon && ( */}
            {/*  <span className="flex w-5 h-5 me-2 items-center justify-center"> */}
            {/*    {getIcon({ */}
            {/*      iconList: groupIcons, */}
            {/*      iconName: selectedMenu?.icon, */}
            {/*      className: 'max-h-full max-w-full', */}
            {/*    })} */}
            {/*  </span> */}
            {/* )} */}

            <div
              className="whitespace-nowrap"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {['desc', 'asc'].includes(query.sortedBy) ? (
                <>
                  <span style={{
                    fontSize: 32
                  }}>&nbsp;$&nbsp;</span>
                  <ArrowDownIcon
                    className={cn('h-3 w-3', {
                      'transform rotate-180': query.sortedBy === 'desc',
                    })}
                  />
                </>
              ) : <img src='/icons/sorting-icon.png' className='w-6 h-6' />}
              {/* {sortValueState !== 'default' && !sortMode  && !query.sortedBy && t("sort")} */}
              {/* {sortValueState !== 'default' && !sortMode  && !query.sortedBy && <IconSortNo />} */}
              {/* {!query.sortedBy && !sortMode  && sortValueState === 'default' && `${t("sort")}`} */}
              {/* {!query.sortedBy && !sortMode  && sortValueState === 'default' && <IconSortNo />} */}
              {/* {!query.sortedBy && sortMode  && sortValueState === 'default' && `${t("default_sort_by_price")}`} */}
              {/* {!query.sortedBy && sortMode  && sortValueState === 'default' && <IconSortNo />} */}
              {/* {query.sortedBy === 'asc' && `${t("ascending_sort_by_price")}`} */}
              {/* {query.sortedBy === 'asc' && <IconSortAsc />} */}
              {/* {query.sortedBy === 'desc' && `${t("descending_sort_by_price")}`} */}
              {/* {query.sortedBy === 'desc' && <IconSortDesc />}*/}
              {/* {query.sortedBy === 'desc' && <IconCaretUp />}*/}
            </div>
            {/*<span className="flex ps-2.5 pt-1 ms-auto">*/}
            {/*  {variant === 'colored' && (*/}
            {/*    <CaretDown*/}
            {/*      className={open ? 'transform rotate-180' : undefined}*/}
            {/*    />*/}
            {/*  )}*/}

            {/*  {variant === 'minimal' && (*/}
            {/*    <ArrowDownIcon*/}
            {/*      className={cn('h-3 w-3', {*/}
            {/*        'transform rotate-180': open,*/}
            {/*      })}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*</span>*/}
          </>
        )}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="ul"
          className={cn(
            'absolute  mt-2  py-2 w-48 h-56 lg:h-auto min-h-40 max-h-56 sm:max-h-72 bg-light rounded shadow-700 focus:outline-none',
            {
              'border border-border-200 end-0 origin-top-end':
                variant === 'minimal',
              'end-0 origin-top-end xl:origin-top-start':
                variant !== 'minimal',
            }
          )}
        >
          <Scrollbar
            className="w-full h-full"
            options={{
              scrollbars: {
                autoHide: 'never',
              },
            }}
          >

            {/* {groups?.map(({ id, name, slug }) => ( */}
            {/*  <Menu.Item key={id}> */}
            {/*    {({ active }) => ( */}
            {/*      <Link */}
            {/*        href={`/${slug}`} */}
            {/*        className={cn( */}
            {/*          'flex space-s-4 items-center w-full px-5 py-2.5 text-sm font-semibold capitalize  transition duration-200 hover:text-accent focus:outline-none', */}
            {/*          active ? 'text-accent' : 'text-body-dark' */}
            {/*        )} */}
            {/*      > */}
            {/*        /!*{icon && variant === 'colored' && (*!/ */}
            {/*        /!*  <span className="flex w-5 h-5 items-center justify-center">*!/ */}
            {/*        /!*    {getIcon({*!/ */}
            {/*        /!*      iconList: groupIcons,*!/ */}
            {/*        /!*      iconName: icon,*!/ */}
            {/*        /!*      className: 'max-h-full max-w-full',*!/ */}
            {/*        /!*    })}*!/ */}
            {/*        /!*  </span>*!/ */}
            {/*        /!*)}*!/ */}
            {/*        <span>{name}</span> */}
            {/*      </Link> */}
            {/*    )} */}
            {/*  </Menu.Item> */}
            {/* ))} */}

            {Object.entries(priceSortValues).map(([key, value], idx) => (
              <Menu.Item key={idx} onClick={() => {
                setSortMode(!sortMode)
                setSortValueState(key)
                sortState(key)
              }}>
                {({ active }) => (
                  <div
                    className={cn(
                      'flex space-s-4 items-center w-full px-5 py-2.5 text-sm font-semibold capitalize  transition duration-200 hover:text-accent focus:outline-none',
                      active ? 'text-accent' : 'text-body-dark'
                    )}
                  >
                    {/* {icon && variant === 'colored' && ( */}
                    {/*  <span className="flex w-5 h-5 items-center justify-center"> */}
                    {/*    {getIcon({ */}
                    {/*      iconList: groupIcons, */}
                    {/*      iconName: icon, */}
                    {/*      className: 'max-h-full max-w-full', */}
                    {/*    })} */}
                    {/*  </span> */}
                    {/* )} */}
                    <span>{value}</span>
                  </div>
                )}
              </Menu.Item>
            ))}

          </Scrollbar>
        </Menu.Items>
      </Transition>
    </Menu>
      </>
  );
};

export default observer(GroupsMenu);

