import cn from "classnames";
import {useRouter} from "next/router";
import dayjs from "dayjs";
import {useTranslation} from "next-i18next";

require("../../../assets/dayjs_locales/ru");
require("../../../assets/dayjs_locales/uk");


function ChangeLanguageMenu({ allowArrow = false, isMobileMenu = false }) {
  const { t } = useTranslation('common');
  const router = useRouter();

  // const languages = {'ru': `${t('text-language-russian')}`, 'en': `${t('text-language-english')}`, 'uk': `${t('text-language-ukrainian')}`}
  const languages = {
    'ru': "Русский",
    'en': "English",
    'uk': "Українська"
  }
  dayjs.locale(router.locale)
  // return null;
  return <select
    className={cn({
        "px-4 flex items-center w-full transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 dropdown": true,
        "appearance-none": !allowArrow,
        "text-xl": isMobileMenu
      },
      // shadow && 'focus:shadow',
      // variantClasses[variant],
      // sizeClasses[dimension],
      // disabled && 'bg-gray-100 cursor-not-allowed',
      // inputClassName
    )}
    value={router.locale}
    onChange={(event) => {
      // setLanguage(event.target.value);
      dayjs.locale(event.target.value);
      router.push(router.asPath, router.asPath, { locale: event.target.value })
      // i18n.changeLanguage(event.target.value);
    }}>
    {/* router.locales */['uk', 'en'].map(locale => <option key={locale} value={locale}>{languages[locale]}</option>)}
  </select>
}

export default ChangeLanguageMenu
