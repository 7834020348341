import Link from '@components/ui/link';
import { desktopHeaderLinks } from '@lib/routes';
import {i18n, useTranslation} from 'next-i18next';
import {useRouter} from "next/router";
import cn from "classnames";
import ChangeLanguageMenu from "@components/layouts/menu/change-language-menu";

const StaticMenu = () => {
  const { t } = useTranslation('common');
  const router = useRouter();

  return (
    <>
      {desktopHeaderLinks.map(({ href, label, icon }) => (
        <li key={`${href}${label}`}>
          <Link
            href={href}
            className="font-normal text-heading flex items-center transition duration-200 no-underline hover:text-accent focus:text-accent whitespace-nowrap"
          >
            {icon && <span className="me-2">{icon}</span>}
            {t(label)}
          </Link>
        </li>
      ))}
      <ChangeLanguageMenu />
    </>
  );
};

export default StaticMenu;
