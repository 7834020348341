import { GroupsMenu } from '@components/layouts/menu/groups-menu';
import useHomepage from '@framework/utils/use-homepage';
import {useProductsQuery} from "@framework/products/products.query";
import {useRouter} from "next/router";
import {observer} from "mobx-react";
import { useGroupsQuery } from './groups.query';

interface Props {
  variant?: 'colored' | 'minimal';
  sortState?: (state) => string | void;
  priceSortValues?: object;
}

const GroupsDropdownMenu = ({ variant = 'colored', sortState, priceSortValues }: Props) => {
  // const { isLoading: loading, data, error } = useGroupsQuery();
  const { homePage } = useHomepage();

  const { query } = useRouter();
  const group = (query.pages?.[0] as string) ?? (homePage?.slug as string);

  const updateState = (state) => {
    sortState(state);
  }
  return (
    <GroupsMenu
      // groups={data?.types}
      sortState={updateState}
      priceSortValues={priceSortValues}
      defaultGroup={homePage}
      variant={variant}
    />
  );
};

export default observer(GroupsDropdownMenu);
